import React, { Component } from 'react';
import '../styles/_about.scss';

class About extends Component {
  componentDidMount() {
    this.runCounter();
  }

  runCounter = () => {
    const popstat = 7325365579;
    const poprate = 2.4452;
    const today = new Date();
    const statsdate = new Date('May 18, 2016');
    const offset = today.getTimezoneOffset() * 60 * 1000;
    const diffpop = (today.getTime() + offset - statsdate.getTime()) / 1000;
    let newpop = Math.ceil(popstat + diffpop * poprate);
    newpop = '' + newpop;
    const p1 = newpop.substring(0, 1);
    const p2 = newpop.substring(1, 4);
    const p3 = newpop.substring(4, 7);
    const p4 = newpop.substring(7, 10);
    const pops = p1 + ',' + p2 + ',' + p3 + ',' + p4;
    const popul = document.getElementById('poo');
    popul.innerHTML = pops;
    setTimeout(() => {
      this.runCounter();
    }, 200);
  };

  render() {
    return (
      <>
        <div className="darkwrapper" />
        <div id="aboutWrapper">
          <div id="leadership">
            <h3 id="leadershiptitle">LEADERSHIP</h3>
            <p>M.L. Fleming</p>
            <p>Derrick Turton</p>
            <p>Fredrick Acker</p>
            <p>Eric Taggart</p>

            <h3 id="management">MANAGEMENT</h3>
            <p>Laura Shand</p>
          </div>
          <div id="medianewslogo">
            <h1>Media + News</h1>
          </div>
          <div id="limitedto">
            <h2>We aren't limited to an industry.</h2>
            <p>
              Media + News creates visual, thought-provoking, interactive <br />
              content for the <span id="poo" />
              &nbsp;people on earth. Our palette <br />
              is comprised of a vast cultural spectrum. We endeavor with <br />
              brands to discover, innovate and furnish striking identities.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default About;
