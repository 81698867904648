import React, { Component } from 'react';
import { TimelineMax, TweenMax } from 'gsap/all';
import imagesLoaded from 'imagesloaded';
import CustomEase from '../library/CustomEase';
import '../styles/_hello.scss';

class Hello extends Component {
  componentDidMount() {
    imagesLoaded('.server', () => {
      this.helloSquares();
    });
    this.layoutSlider();
  }

  close = () => {
    const content = document.querySelector('.content');

    content.style.display = 'none';
    Array.from(document.getElementsByClassName('server'), element =>
      element.classList.remove('animate')
    );
  };

  serverClick = (top, square, slide) => {
    const content = document.querySelector('.content');
    const slideFrame = document.querySelector('.slideFrame');
    const animateSquare = document.getElementById(square);
    const activeSlide = document.getElementById(slide);

    Array.from(document.getElementsByClassName('ind'), element =>
      element.classList.remove('active')
    );
    activeSlide.classList.add('active');
    slideFrame.style.top = top;
    animateSquare.style.transition = '550ms';
    animateSquare.classList.add('animate');
    content.style.display = 'block';
    content.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 250,
    });
  };

  helloSquares = () => {
    const tl = new TimelineMax();

    tl.set('.server', { visibility: 'visible' })
      .from(
        '#redsquare',
        0.9,
        {
          x: '-50%',
          y: '-115%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#graysquare',
        0.9,
        {
          x: '-50%',
          y: '-125%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#ltbluesquare',
        0.9,
        {
          x: '-50%',
          y: '-125%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#purplesquare',
        0.9,
        {
          y: '-135%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#yellowsquare',
        0.9,
        {
          y: '-155%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#bluesquare',
        0.9,
        {
          y: '-145%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .from(
        '#greensquare',
        0.9,
        {
          y: '-165%',
          ease: CustomEase.create(
            'custom',
            'M0,0,C0.077,0.152,0.355,0.855,0.682,1.09,0.792,1.169,0.871,1,1,1'
          ),
          clearProps: 'transform',
        },
        0.1
      )
      .set('.bar', { visibility: 'visible' })
      .fromTo(
        '#foundationBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '25%',
        },
        0.9
      )
      .fromTo(
        '#whoBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '20%',
        },
        1.2
      )
      .fromTo(
        '#whatBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '60%',
        },
        1.5
      )
      .fromTo(
        '#whenBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '60%',
        },
        1.8
      )
      .fromTo(
        '#whereBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '25%',
        },
        2.1
      )
      .fromTo(
        '#whyBar',
        0.3,
        {
          width: '0%',
        },
        {
          width: '40%',
        },
        2.4
      );
  };

  layoutSlider = () => {
    const el = document.querySelector('.layout_slider');
    const indicator = el.querySelector('.indicator');
    const ind = Array.from(indicator.getElementsByClassName('ind'));
    const nodes = Array.prototype.slice.call(indicator.children);
    const slideFrame = el.querySelector('.slideFrame');
    const slide = slideFrame.querySelectorAll('.slide');
    let idx = 0;
    const idxMax = slide.length - 1;
    const indScale = 1;
    const anispeed = 0.4;
    let animating = false;

    TweenMax.set(ind[0], {
      scale: indScale,
    });

    document.addEventListener('keyup', eventHandler);

    if (document.getElementById('layout_slider').addEventListener) {
      document
        .getElementById('layout_slider')
        .addEventListener('mousewheel', eventHandler, false);
      document
        .getElementById('layout_slider')
        .addEventListener('DOMMouseScroll', eventHandler, false);
    } else {
      document
        .getElementById('layout_slider')
        .attachEvent('onmousewheel', eventHandler);
    }

    // indicator event
    ind.map(i => {
      i.addEventListener('click', e => {
        Array.from(document.getElementsByClassName('server'), element =>
          element.classList.remove('animate')
        );

        idx = nodes.indexOf(e.target);
        moveFrameTo(idx);

        return false;
      });
    });

    function eventHandler(e) {
      e = e || window.event;
      var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));

      if (delta === 1 || e.keyCode === '38') {
        toPrevSlide();
      }
      if (delta === -1 || e.keyCode === '40') {
        toNextSlide();
      }
      if (e.keyCode === '27') {
        const content = document.querySelector('.content');

        content.style.display = 'none';
        Array.from(document.getElementsByClassName('server'), element =>
          element.classList.remove('animate')
        );
      }
      return false;
    }

    function moveFrameTo(idx) {
      var slideSpeed = getSlideSpeed(idx);

      animating = true;
      TweenMax.to(slideFrame, slideSpeed, {
        top: idx * -slide[0].offsetHeight,
        ease: 'Power3.easeOut',
        onComplete: function() {
          animating = false;
        },
      });
      activeInd(idx);
    }

    const getSlideSpeed = idx => {
      var prevIdx = nodes.indexOf(idx),
        slideSpeed = Math.abs(prevIdx - idx) * anispeed;

      return slideSpeed;
    };
    const activeInd = idx => {
      Array.from(
        document.getElementsByClassName('server'),
        element => (
          element.classList.remove('animate'),
          (element.style.transition = '550ms')
        )
      );

      if (idx === 0) {
        document.getElementById('greensquare').classList.add('animate');
      } else if (idx === 1) {
        document.getElementById('yellowsquare').classList.add('animate');
      } else if (idx === 2) {
        document.getElementById('bluesquare').classList.add('animate');
      } else if (idx === 3) {
        document.getElementById('purplesquare').classList.add('animate');
      } else if (idx === 4) {
        document.getElementById('ltbluesquare').classList.add('animate');
      } else if (idx === 5) {
        document.getElementById('graysquare').classList.add('animate');
      } else {
        document.getElementById('redsquare').classList.add('animate');
      }

      var anispeed = 0.1;

      Array.from(document.getElementsByClassName('ind'), element =>
        element.classList.remove('active')
      );
      ind[idx].classList.add('active');

      TweenMax.to(ind, anispeed, {
        scale: 1,
      });
      TweenMax.to(ind[idx], anispeed, {
        scale: indScale,
      });
    };
    const toNextSlide = () => {
      if (!animating) {
        if (idx === idxMax) {
        } else {
          idx = idx + 1;
        }
        moveFrameTo(idx);
      }
    };
    const toPrevSlide = () => {
      if (!animating) {
        if (idx === 0) {
        } else {
          idx = idx - 1;
        }
        moveFrameTo(idx);
      }
    };

    return {
      moveTo: moveFrameTo,
    };
  };

  render() {
    return (
      <>
        <div className="grid">
          <div className="helloTitle">
            <h1>The Hello. Process.</h1>
            <h3>Layers to illustrate our scalable approach.</h3>
          </div>
        </div>
        <div className="ground">
          <img
            src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/streetSign.svg"
            id="streetSign"
            alt="server"
          />
          <div id="ground">
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/foundationbar.svg"
              className="bar red"
              id="foundationBar"
              data-color="red"
              alt="server"
              onClick={() => this.serverClick('-600%', 'redsquare', 'slide7')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/redsquare.svg"
              className="server red"
              id="redsquare"
              data-color="red"
              alt="server"
              onClick={() => this.serverClick('-600%', 'redsquare', 'slide7')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/whobar.svg"
              className="bar gray"
              id="whoBar"
              data-color="gray"
              alt="server"
              onClick={() => this.serverClick('-500%', 'graysquare', 'slide6')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/graysquare.svg"
              className="server gray"
              id="graysquare"
              data-color="gray"
              alt="server"
              onClick={() => this.serverClick('-500%', 'graysquare', 'slide6')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/whatbar.svg"
              className="bar ltblue"
              id="whatBar"
              data-color="ltblue"
              alt="server"
              onClick={() =>
                this.serverClick('-400%', 'ltbluesquare', 'slide5')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/ltbluesquare.svg"
              className="server ltblue"
              id="ltbluesquare"
              data-color="ltblue"
              alt="server"
              onClick={() =>
                this.serverClick('-400%', 'ltbluesquare', 'slide5')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/whenbar.svg"
              className="bar purple"
              id="whenBar"
              data-color="purple"
              alt="server"
              onClick={() =>
                this.serverClick('-300%', 'purplesquare', 'slide4')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/yellowsquare.svg"
              className="server yellow"
              id="yellowsquare"
              data-color="yellow"
              alt="server"
              onClick={() =>
                this.serverClick('-100%', 'yellowsquare', 'slide2')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/wherebar.svg"
              className="bar blue"
              id="whereBar"
              data-color="blue"
              alt="server"
              onClick={() => this.serverClick('-200%', 'bluesquare', 'slide3')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/bluesquare.svg"
              className="server blue"
              id="bluesquare"
              data-color="blue"
              alt="server"
              onClick={() => this.serverClick('-200%', 'bluesquare', 'slide3')}
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/whybar.svg"
              className="bar yellow"
              id="whyBar"
              data-color="yellow"
              alt="server"
              onClick={() =>
                this.serverClick('-100%', 'yellowsquare', 'slide2')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/purplesquare.svg"
              className="server purple"
              id="purplesquare"
              data-color="purple"
              alt="server"
              onClick={() =>
                this.serverClick('-300%', 'purplesquare', 'slide4')
              }
            />
            <img
              src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/greensquare.svg"
              className="server green"
              id="greensquare"
              data-color="green"
              alt="server"
              onClick={() => this.serverClick('0%', 'greensquare', 'slide1')}
            />
          </div>
          <img
            src="https://res.cloudinary.com/mattkellough/image/upload/v1556735749/media-news/new-ground.svg"
            id="ground-bg"
            alt="server"
          />
        </div>
        <section id="content" className="content">
          <div className="close" onClick={() => this.close()}>
            <div className="outer">
              <div className="inner">
                <label>Close</label>
              </div>
            </div>
          </div>
          <div id="layout_slider" className="layout_slider">
            <nav className="indicator">
              <a id="slide1" className="ind" data-color="green">
                H<sup>7</sup>
              </a>
              <a id="slide2" className="ind" data-color="yellow">
                W<sup>6</sup>
              </a>
              <a id="slide3" className="ind" data-color="blue">
                W<sup>5</sup>
              </a>
              <a id="slide4" className="ind" data-color="purple">
                W<sup>4</sup>
              </a>
              <a id="slide5" className="ind" data-color="ltblue">
                W<sup>3</sup>
              </a>
              <a id="slide6" className="ind" data-color="gray">
                W<sup>2</sup>
              </a>
              <a id="slide7" className="ind" data-color="red">
                F<sup>1</sup>
              </a>
            </nav>
            <div className="slideFrame">
              <section id="slide1" className="slide" data-color="green">
                <h2>Hello.</h2>
              </section>
              <section id="slide2" className="slide" data-color="yellow">
                <p>
                  Why: No, the answer is not return on investment. Food for
                  thought.
                </p>
              </section>
              <section id="slide3" className="slide" data-color="blue">
                <p>
                  Where: We’ve found this to be the most arduous component to
                  measure
                  <br />
                  due to the phase from brick and mordar to digital portals.
                  Some of our
                  <br />
                  case studies confrim some publicly traded fortune 500
                  companies have
                  <br />
                  80% of their revenue from mobile devices. That’s up 23%
                  year-over-year
                  <br />
                  and 76% within the last five years.
                </p>
              </section>
              <section id="slide4" className="slide" data-color="purple">
                <p>
                  When: The retention rate of a customer is 4-8 seconds. In that
                  time period
                  <br />a brand must interest, excite and converge. Time of day,
                  day of the week,
                  <br />
                  weather, and season of the year determine a customers behaivor
                  pattern.
                </p>
              </section>
              <section id="slide5" className="slide" data-color="ltblue">
                <p>
                  What: Economic data shows over $70 trillion within 49
                  industries around
                  <br />
                  the globe is generated annually. Therein lies milions of
                  business’s, 94,000
                  <br />
                  new companies being registered and over 250,000,000 products
                  sold in the
                  <br />
                  United States each year alone. What is being consumed by the
                  317,637,267
                  <br />
                  U.S. citizens leaves marginal opportunity for brands to
                  converge in the
                  <br />
                  market while leaving an indelible mark on their cosumers.
                </p>
              </section>
              <section id="slide6" className="slide" data-color="gray">
                <p>
                  Who: Yes, there is age, race, gender, relgion etc. but what
                  about appetite,
                  <br />
                  emotion and the palpable 1:1 interactive experience with these
                  individuals.
                  <br />
                  This exponent is in league by itself; a variable overlooked by
                  many.{' '}
                </p>
              </section>
              <section id="slide7" className="slide" data-color="red">
                <p>
                  Foundation: Using metrics and analytics to gauge market
                  senitement, track
                  <br />
                  performance of trends, create forecast reports of a companies
                  vision, goals
                  <br />
                  and time horizon of a campaign aids in effectively reaching a
                  core audience.
                </p>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hello;
