import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes';
import FancyRoute from './components/tools/FancyRoute';
import WebFont from 'webfontloader';
import './styles/Application.scss';

WebFont.load({
  google: {
    families: ['Lato', 'sans-serif']
  }
});

const App = props => (
  <div className={`container height-100 ${window.location.href.split('/')[3]}`}>
    <nav id="navbar">
      <ul>
        <li id="Solutions">
          <a href="/solutions/">Solutions</a>
        </li>
        <li id="Work">
          <a href="/work/">Work</a>
        </li>
        <li id="logo">
          <a href="/">M + N</a>
        </li>
        <li id="About">
          <a href="/about/">About</a>
        </li>
        <li id="Contact">
          <a href="/contact/">Contact</a>
        </li>
      </ul>
    </nav>
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <FancyRoute key={i} {...route} />
        ))}
      </Switch>
    </Router>
  </div>
);

export default App;
