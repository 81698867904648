import React, { Component } from 'react';
import '../styles/_home.scss';

class Home extends Component {
  render() {
    return (
      <>
        <div id='navwrapper'>
          <div id='landingcopy'>
            <div id='landingtexth2'>
              <h1>It starts with a vision.</h1>
            </div>
            <div id='landingtext'>
              <p id='landingtextp'>
                Media + News is a research-based marketing and design consulting firm. <br/>
                Founded in 2008 focused on aesthetic simplicity, the art of <br/>
                creating organic growth; in our pursuit to form 1:1 interactive <br/>
                expericences across industry. To date, 23% of the world <br/>
                populous ± a few animals have been immersed in our vision. <br/>
              </p>
            </div>
            <div className='button'>
              <a href='/hello/'>
                <button id='Explore'>
                  Explore
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='footer'>
          <button id='ExploreMobile'>
            <a href='/hello/'>Explore</a>
          </button>
        </div>
        <div className='bg'>
          <div id='satellite'>
            <img src='https://res.cloudinary.com/mattkellough/image/upload/v1556735733/media-news/mnsatellite.svg'/>
          </div>
          <div id='cloud-wrap'>
            <svg className='svg-sun' version='1.1' viewBox='0 0 100 100' preserveAspectRatio='xMinYMin meet'>
              <circle cx='50' cy='50' r='35' id='sun'></circle>
            </svg>
            <div className='x1'>
              <div className='cloud1'></div>
            </div>
            <div className='x4'>
              <div className='cloud2'></div>
            </div>

            <div className='x5'>
              <div className='cloud1'></div>
            </div>
          </div>
          <div id='sailBoat'>
            <img src='https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/sailBoat.svg' />
          </div>
        </div>
      </>
    );
  }
}

export default Home;