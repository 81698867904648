import React, { Component } from 'react';
import flatpickr from 'flatpickr';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/effects/effect-slide';
import '../styles/_contact.scss';

class Contact extends Component {
  componentDidMount() {
    function childrenPosition() {
      var slideWidth = 1;
      $('.wrapper')
        .find('.form-slide')
        .each(function() {
          var nextSlide = slideWidth + '00%';
          $(this)
            .next()
            .css('left', nextSlide);
          slideWidth++;
        });
    }

    childrenPosition();

    var sliderListItem = $('.form-slide'),
      slideWidth = sliderListItem.width(),
      sliderCount = sliderListItem.length;

    console.log(sliderCount);

    let i = 0;

    function moveLeft() {
      if (i !== 0) {
        sliderListItem.animate(
          {
            left: '+=' + slideWidth,
          },
          500,
          'easeInSine',
          function() {
            if (i > 0) {
              $('.arrow-prev').show();
            }
            if (i < 2) {
              $('.arrow-next').show();
            }
            if (i === 0) {
              $('.arrow-prev').hide();
            }
          }
        );
        i--;
        $('.nav-dots')
          .children('.active')
          .removeClass('active')
          .prev()
          .addClass('active');
      }
      console.log(i);
    }

    function moveRight() {
      if (i !== 2) {
        sliderListItem.animate(
          {
            left: '-=' + slideWidth,
          },
          500,
          'easeInSine',
          function() {
            if (i > 0) {
              $('.arrow-prev').show();
            }
            if (i === 2) {
              $('.arrow-next').hide();
            }
          }
        );
        i++;
        $('.nav-dots')
          .children('.active')
          .removeClass('active')
          .next()
          .addClass('active');
      }
      console.log(i);
    }

    $('input[type="file"]').change(function(element) {
      var fileName = '';
      if (element.target.value)
        fileName = element.target.value.split('\\').pop();
      $('.default').text(fileName);
      $('.default').css('background-color', 'rgba(143,193,86,0.3)');
    });

    flatpickr('.flatpickr', {
      altInput: true,
      altFormat: 'F j, Y',
    });

    (function($) {
      var CheckboxDropdown = function(el) {
        var _this = this;
        this.isOpen = false;
        this.areAllChecked = false;
        this.$el = $(el);
        this.$label = this.$el.find('.dropdown-label');
        this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
        this.$inputs = this.$el.find('[type="checkbox"]');

        this.onCheckBox();

        this.$label.on('click', function(e) {
          e.preventDefault();
          _this.toggleOpen();
        });

        this.$checkAll.on('click', function(e) {
          e.preventDefault();
          _this.onCheckAll();
        });

        this.$inputs.on('change', function(e) {
          _this.onCheckBox();
        });
      };

      CheckboxDropdown.prototype.onCheckBox = function() {
        this.updateStatus();
      };

      CheckboxDropdown.prototype.updateStatus = function() {
        var checked = this.$el.find(':checked');

        this.areAllChecked = false;
        this.$checkAll.html('Check All');

        if (checked.length <= 0) {
          this.$label.html('Select Options');
        } else if (checked.length === 1) {
          this.$label.html(checked.parent('label').text());
        } else if (checked.length === this.$inputs.length) {
          this.$label.html('All Selected');
          this.areAllChecked = true;
          this.$checkAll.html('Uncheck All');
        } else {
          this.$label.html(checked.length + ' Selected');
        }
      };

      CheckboxDropdown.prototype.onCheckAll = function(checkAll) {
        if (!this.areAllChecked || checkAll) {
          this.areAllChecked = true;
          this.$checkAll.html('Uncheck All');
          this.$inputs.prop('checked', true);
        } else {
          this.areAllChecked = false;
          this.$checkAll.html('Check All');
          this.$inputs.prop('checked', false);
        }

        this.updateStatus();
      };

      CheckboxDropdown.prototype.toggleOpen = function(forceOpen) {
        var _this = this;

        if (!this.isOpen || forceOpen) {
          this.isOpen = true;
          this.$el.addClass('on');
          $(document).on('click', function(e) {
            if (!$(e.target).closest('[data-control]').length) {
              _this.toggleOpen();
            }
          });
        } else {
          this.isOpen = false;
          this.$el.removeClass('on');
          $(document).off('click');
        }
      };

      var checkboxesDropdowns = document.querySelectorAll(
        '[data-control="checkbox-dropdown"]'
      );
      for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
        new CheckboxDropdown(checkboxesDropdowns[i]);
      }
    })($);

    $('#solution').change(function() {
      if ($('#solution').val() !== null) {
        $(this).css('color', '#06489c');
        $(this).css(
          'background',
          'linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #1277f6 4%)'
        );
        $(this).css('background-position', '0 0');
        $(this).css('background-size', '200px 100%');
      }
    });

    $('.arrow-next').click(function() {
      moveRight();
      return false;
    });
    $('.arrow-prev').click(function() {
      moveLeft();
      return false;
    });

    $('.arrow-next').click(function() {
      return false;
    });

    $(function() {
      // Initiate Slider
      $('#slider-range').slider({
        range: true,
        min: 10000,
        max: 250000,
        step: 100,
        values: [105000, 150000],
      });

      // Move the range wrapper into the generated divs
      $('.ui-slider-range').append($('.range-wrapper'));

      // Apply initial values to the range container
      $('.range').html(
        '<span class="range-value">$' +
          $('#slider-range')
            .slider('values', 0)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
          '</span>-<span class="range-value">$' +
          $('#slider-range')
            .slider('values', 1)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
          '</span>'
      );
      $('#amount').val(
        '$' +
          $('#slider-range')
            .slider('values', 0)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      );
      $('#amount2').val(
        '$' +
          $('#slider-range')
            .slider('values', 1)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      );

      // Show the gears on press of the handles
      $('.ui-slider-handle, .ui-slider-range').on('mousedown', function() {
        $('.gear-large').addClass('active');
      });

      // Hide the gears when the mouse is released
      // Done on document just incase the user hovers off of the handle
      $(document).on('mouseup', function() {
        if ($('.gear-large').hasClass('active')) {
          $('.gear-large').removeClass('active');
        }
      });

      // Rotate the gears
      var gearOneAngle = 0,
        gearTwoAngle = 0,
        rangeWidth = $('.ui-slider-range').css('width');

      $('.gear-one').css('transform', 'rotate(' + gearOneAngle + 'deg)');
      $('.gear-two').css('transform', 'rotate(' + gearTwoAngle + 'deg)');

      $('#slider-range').slider({
        slide: function(event, ui) {
          // Update the range container values upon sliding

          $('.range').html(
            '<span class="range-value">$' +
              ui.values[0]
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
              '</span>-</span><span class="range-value">$' +
              ui.values[1]
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
              '</span>'
          );
          $('#amount').val(
            '$' +
              $('#slider-range')
                .slider('values', 0)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          );
          $('#amount2').val(
            '$' +
              $('#slider-range')
                .slider('values', 1)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
          );

          // Get old value
          var previousVal = parseInt($(this).data('value'));

          // Save new value
          $(this).data({
            value: parseInt(ui.value),
          });

          // Figure out which handle is being used
          if (ui.values[0] == ui.value) {
            // Left handle
            if (previousVal > parseInt(ui.value)) {
              // value decreased
              gearOneAngle -= 7;
              $('.gear-one').css(
                'transform',
                'rotate(' + gearOneAngle + 'deg)'
              );
            } else {
              // value increased
              gearOneAngle += 7;
              $('.gear-one').css(
                'transform',
                'rotate(' + gearOneAngle + 'deg)'
              );
            }
          } else {
            // Right handle
            if (previousVal > parseInt(ui.value)) {
              // value decreased
              gearOneAngle -= 7;
              $('.gear-two').css(
                'transform',
                'rotate(' + gearOneAngle + 'deg)'
              );
            } else {
              // value increased
              gearOneAngle += 7;
              $('.gear-two').css(
                'transform',
                'rotate(' + gearOneAngle + 'deg)'
              );
            }
          }

          if (ui.values[1] === 250000) {
            if (!$('.range-alert').hasClass('active')) {
              $('.range-alert').addClass('active');
            }
          } else {
            if ($('.range-alert').hasClass('active')) {
              $('.range-alert').removeClass('active');
            }
          }
        },
      });

      // Prevent the range container from moving the slider
      $('.range, .range-alert').on('mousedown', function(event) {
        event.stopPropagation();
      });
    });
  }
  render() {
    return (
      <>
        <div className="contactForm">
          <form>
            <h1>Lets Work. Together.</h1>
            <h3>Help us by filling in the blanks.</h3>
            <input placeholder="Name" type="text" required={true} name="name" />
            <input
              placeholder="Company"
              type="text"
              required={true}
              name="company"
            />
            <div
              className="dropdown"
              data-control="checkbox-dropdown"
              id="solution"
            >
              <label className="dropdown-label">Solution</label>
              <div className="dropdown-list">
                <label className="dropdown-option">
                  Strategy
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="strategy"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Analytics
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="analytics"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Media Planning
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 3"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  R &amp; D
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 4"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Creative
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 5"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Video Production
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 5"
                  />
                </label>
              </div>
            </div>
            <input
              placeholder="Email"
              type="text"
              required={true}
              name="email"
            />
            <input
              placeholder="Phone Number"
              type="tel"
              required={true}
              name="tel"
              id="phone-input"
              pattern="[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}"
            />
            <input
              placeholder="Industry"
              type="text"
              required={true}
              name="industry"
            />
            <br />
            <input type="text" id="amount" name="lowAmount" readOnly />
            <input type="text" id="amount2" name="highAmount" readOnly />
            <div className="wrapper">
              <div className="budget form-slide">
                <p id="budget">Budget</p>
                <div className="slider-wrapper">
                  <div id="slider-range" />
                  <div className="range-wrapper">
                    <div className="range" />
                    <div className="range-alert">+</div>
                  </div>
                  <div className="marker marker-0">$10,000</div>
                  <div className="marker marker-25">$70,000</div>
                  <div className="marker marker-50">$130,000</div>
                  <div className="marker marker-75">$190,000</div>
                  <div className="marker marker-100">$250,000+</div>
                </div>
              </div>
              <div className="calendar form-slide">
                <div id="calendarOptions">
                  <select name="frequency" id="frequency" defaultValue="">
                    <option value="" disabled>
                      Frequency
                    </option>
                    <option value="one">One Time</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="annually">Annually</option>
                  </select>
                  <input
                    className="flatpickr"
                    type="text"
                    placeholder="Start"
                    id="start"
                    data-input
                  />
                  <a className="input-button" data-clear>
                    <i className="icon-close" />
                  </a>
                  <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/rightArrow.svg" />
                  <input
                    className="flatpickr"
                    type="text"
                    placeholder="Finish"
                    id="end"
                    data-input
                  />
                  <input
                    type="text"
                    name="location"
                    placeholder="Location"
                    id="location"
                  />
                </div>
              </div>
              <div className="textarea form-slide">
                <textarea
                  id="detailsText"
                  name="Details"
                  placeholder="Did we miss anything?"
                />
                <input id="upfile" type="file" name="files" multiple />
                <div className="file-dummy">
                  <div className="default">Drag &amp; Drop A File</div>
                </div>
              </div>
            </div>

            <ul className="nav-dots">
              <li className="active" />
              <li />
              <li />
            </ul>
            <a href="" className="arrow-prev">
              <svg width="30px" height="30px" viewBox="0 0 50 80">
                <polyline
                  fill="none"
                  stroke="#1277f6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="
              45.63,75.8 0.375,38.087 45.63,0.375 "
                />
              </svg>
            </a>
            <a href="" className="arrow-next">
              <svg width="30px" height="30px" viewBox="0 0 50 80">
                <polyline
                  fill="none"
                  stroke="#1277f6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="
              0.375,0.375 45.63,38.087 0.375,75.8 "
                />
              </svg>
            </a>
            <button>Submit</button>
          </form>
        </div>
        <div className="terms">
          <p>
            <a href="#">Terms of Use</a> | <a href="#">Privacy Policy</a> |{' '}
            <a href="#">Copyright</a>
          </p>
        </div>
        <div className="bg">
          <div id="satellite">
            <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/mnsatellite.svg" />
          </div>
          <div id="cloud-wrap">
            <svg
              className="svg-sun"
              version="1.1"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMinYMin meet"
            >
              <circle cx="50" cy="50" r="35" id="sun" />
            </svg>
            <div className="x1">
              <div className="cloud1" />
            </div>
            <div className="x4">
              <div className="cloud2" />
            </div>
            <div className="x5">
              <div className="cloud1" />
            </div>
          </div>
          <div id="sailBoat">
            <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/sailBoat.svg" />
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
