import Home from './views/Home';
import About from './views/About';
import Contact from './views/Contact';
import Hello from './views/Hello';
import Solutions from './views/Solutions';
import Work from './views/Work';
import Advertising from './views/Advertising';
import Visuals from './views/Visuals';

const routes = [
  {
    title: 'Home',
    path: '/',
    exact: true,
    component: Home,
  },
  {
    title: 'Contact',
    path: '/contact/',
    component: Contact,
  },
  {
    title: 'About',
    path: '/about/',
    component: About,
  },
  {
    title: 'Hello',
    path: '/hello/',
    component: Hello,
  },
  {
    title: 'Solutions',
    path: '/solutions/',
    component: Solutions,
  },
  {
    title: 'Work',
    path: '/work/',
    component: Work,
  },
  {
    title: 'Advertising',
    path: '/advertising/',
    component: Advertising,
  },
  {
    title: 'Visuals',
    path: '/visuals/',
    component: Visuals,
  },
];

export default routes;
