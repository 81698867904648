import React, { Component } from 'react';
import '../styles/_advertising.scss';

class Advertising extends Component {
  render() {
    return (
      <>
        <div id="campaigns">
          <div id="voli">Voli Vodka</div>
          <div id="mtv">MTV VMA's</div>
          <div id="pepper">Dr. Pepper</div>
          <div id="barrym">Barry M</div>
          <div id="kfc">KFC</div>
          <div id="bud">Bud Light</div>
          <div id="unforgiveable">Unforgiveable</div>
          <div id="asap">A$AP Rocky</div>
          <div id="pitbullad">Pitbull</div>
          <div id="heat">Miami Heat</div>
        </div>
        <div id="barryimg">
          <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735719/media-news/barrym.jpg" />
        </div>
        <div id="pinkstripes">
          <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735719/media-news/pinkstripes.png" />
        </div>
      </>
    );
  }
}

export default Advertising;
