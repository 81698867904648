import React, { Component } from 'react';
import Chart from 'chart.js';
import { CountUp } from 'countup.js';
import '../styles/_work.scss';

class Work extends Component {
  componentDidMount() {
    this.runCounter();
    this.runChart();
  }

  runChart = () => {
    Chart.defaults.global.animationEasing = 'easeInOutBack';
    Chart.defaults.global.animationSteps = 90;
    Chart.defaults.global.responsive = true;
    Chart.defaults.global.scaleOverride = true;
    Chart.defaults.global.scaleShowLabels = false;
    Chart.defaults.global.scaleSteps = 14;
    Chart.defaults.global.scaleStepWidth = 14;
    Chart.defaults.global.scaleStartValue = 0;
    Chart.defaults.global.hoverAnimationDuration = 2000;
    Chart.defaults.global.tooltipFontFamily = 'Lato';
    Chart.defaults.global.tooltipFillColor = '#FFFFFF';
    Chart.defaults.global.tooltipFontColor = '#1277F6';
    Chart.defaults.global.pointHitDetectionRadius = 0;
    Chart.defaults.global.maintainAspectRatio = true;
    Chart.defaults.Line.scaleShowHorizontalLines = false;
    Chart.defaults.Line.scaleShowVerticalLines = false;
    Chart.defaults.Line.scaleGridLineColor = '#55505C';
    Chart.defaults.Line.scaleLineColor = '#55505C';
    Chart.defaults.Line.scaleFontSize = 0;
    Chart.defaults.Line.scaleLineColor = 'transparent';

    const chartElement = document.getElementById('chart').getContext('2d');
    const gradient = chartElement.createLinearGradient(0, 0, 0, 100);
    gradient.addColorStop(0, 'rgba(224, 202, 177, 0.55)');
    gradient.addColorStop(1, 'rgba(224, 202, 177, 0.2)');
    gradient.addColorStop(0.5, 'rgba(224, 202, 177, 0.35)');

    const data = {
      labels: [
        'Jeremih: Press Day',
        'BET Awards',
        'Trina ft. Diddy Million Dollar Girl',
        'Jagged Edge Tip of My Tongue',
        'Calle Ocho',
        'Britto X Trina',
        'Pitbull Miami Heat',
        "Dr. Pepper 'Dream'",
        "Budlight: 'Don't Stop the Party'",
        'Voli Vodka',
        "Def Jam, K'LA",
        'Trina',
        "Pitbull ft. Neyo 'Give Me Everything'",
        'Latin Bill Board Awards',
        'WWE Raw',
        'Universal Studios Grad Bash',
        'Mosley Ent. Timbaland',
        '99 Jamz',
        'LIV X PItbull',
        'Priemos Juventud',
        'Lil Wayne, I Am Music Tour',
        'Miami Marlins',
        'Fountaine Bleu X Pitbull',
        'Pharrell',
        "Jay-Z X Kanye West: 'Watch The Throne'",
        'Jingle Ball Concert',
        "KFC 'Game Day Bucket'",
        'Western Union',
        'LIV',
        "Dick Clark's New Years Rockin' Eve",
        'EZ VIP',
        'NBA AlL-Star Game',
        'Barry-M Cosmetics',
        'Ultra Music Festival',
        '4AD London',
        'Interscope Records',
        'A$AP Rocky',
        'ABT',
        'Ultra Music Festival',
        'Miami Subs',
        'Ultra Music Festival',
        'May FIFA World Cup',
        'Vultron Media',
        'Ultra Music Festival',
        'FALM',
        'Smirnoff Sound Collective',
        'Ultra Music Festival',
      ],

      datasets: [
        {
          label: 'History',
          fillColor: gradient,
          strokeColor: '#1277F6',
          pointColor: '#1277F6',
          pointStrokeColor: '#1277F6',
          pointHighlightFill: '#82C0F6',
          pointHighlightStroke: '#1277F6',
          lineTension: 0.9,
          data: [
            1,
            3,
            5,
            7,
            9,
            11,
            13,
            15,
            17,
            19,
            21,
            23,
            25,
            27,
            29,
            31,
            34,
            37,
            40,
            43,
            47,
            50,
            53,
            56,
            58,
            59,
            60,
            60.7,
            59,
            56,
            53,
            50,
            45,
            41,
            37,
            33,
            29,
            26,
            23,
            20,
            19.7,
            19.6,
            17,
            15,
            14,
            13,
            13,
          ],
        },
      ],
    };

    new Chart(chartElement).Line(data, {
      pointHitDetectionRadius: 0,
      tooltipTemplate: function(d) {
        return data.labels[data.labels.indexOf(d.label)];
      },
    });
  };

  runCounter = () => {
    const popstat = 2300054673;
    const poprate = 1;
    const today = new Date();
    const statsdate = new Date('May 18, 2016');
    const offset = today.getTimezoneOffset() * 60 * 1000;
    const diffpop = (today.getTime() + offset - statsdate.getTime()) / 1000;
    let newpop = Math.ceil(popstat + diffpop * poprate);
    newpop = '' + newpop;
    const p1 = newpop.substring(0, 1);
    const p2 = newpop.substring(1, 4);
    const p3 = newpop.substring(4, 7);
    const p4 = newpop.substring(7, 10);
    const pops = p1 + ',' + p2 + ',' + p3 + ',' + p4;
    const popul = document.getElementById('viewsv');
    popul.innerHTML = pops;
    setTimeout(() => {
      this.runCounter();
    }, 200);
  };

  showHistory = () => {
    Array.from(
      document.querySelectorAll('.keyvalue span'),
      element => (
        element.animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 250,
        }),
        element.style.display = 'none'
      )
    );
    Array.from(
      document.querySelectorAll('.keyvalue img'),
      element => (
        element.animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 250,
        }),
        element.style.display = 'none'
      )
    );
    document.getElementById('sprout').style.bottom = '-30px';
    document.getElementById('stats').style.color = '#1277f6';
    document.getElementById('hist').style.color = '#aaaaaa';
    setTimeout(() => {
      document.getElementById('chart').style.display = 'block';
    }, 250);
  }

  showStats = () => {
    const options = {
      duration: 5,
    };
    const impressions = new CountUp('impressionsv', 28001437, options);
    const comm = new CountUp('commv', 129, options);
    const campaigns = new CountUp('campaignsv', 22, options);
    const awards = new CountUp('awardsv', 9, options);

    impressions.start();
    comm.start();
    campaigns.start();
    awards.start();

    document.getElementById('chart').style.display = 'none';
    Array.from(
      document.querySelectorAll('.keyvalue span'),
      element => (
        (element.style.display = 'block'),
        element.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: 500,
        })
      )
    );
    Array.from(
      document.querySelectorAll('.keyvalue img'),
      element => (
        (element.style.display = 'block'),
        element.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: 500,
        })
      )
    );
    document.getElementById('sprout').style.bottom = '0';
    document.getElementById('stats').style.color = '#aaaaaa';
    document.getElementById('hist').style.color = '#1277F6';
  };

  render() {
    return (
      <>
        <div id="AV">
          <a id="A" href="/advertising/">
            A
          </a>
          <span id="Pipe">|</span>
          <a id="V" href="/visuals/">
            V
          </a>
          <div className="historystats">
            <span id="hist" onClick={() => this.showHistory()}>History </span>
            <span id="stats" onClick={() => this.showStats()}>
              &amp; Stats
            </span>
          </div>
          <div className="keyvalue">
            <div className="flexWrap">
              <div className="views category">
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/apple.svg" />
                <span id="viewsv" />
                <span id="viewsk">Total Views</span>
              </div>
              <div className="impressions category">
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/fingerprint.svg" />
                <span id="impressionsv">28,001,437</span>
                <span id="impressionsk">Impressions</span>
              </div>
              <div className="comm category">
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/picture-collage.svg" />
                <span id="commv">129</span>
                <span id="commk">Comm. &amp; MV's</span>
              </div>
              <div className="campaigns category">
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/campaigns.svg" />
                <span id="campaignsv">22</span>
                <span id="campaignsk">Campaigns</span>
              </div>
              <div className="awards category">
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/grammy.svg" />
                <span id="awardsv">9</span>
                <span id="awardsk">Awards</span>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://res.cloudinary.com/mattkellough/image/upload/v1556735726/media-news/sprout.svg"
          id="sprout"
        />

        <canvas id="chart" />
      </>
    );
  }
}

export default Work;
